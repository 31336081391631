import React, { FC, useState, KeyboardEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { message, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

import Input from '../../../components/bootstrap/forms/Input';
import Label from '../../../components/bootstrap/forms/Label';
import Button from '../../../components/bootstrap/Button';
import { setIsBoarding, setReviewStatus, setUserData } from '../../../redux/slice';
import { LoginResponse, SignupResponse } from '../../../services/AuthApis/types';
import { ErrorResponseType } from '../../../services/ErrorResponseType/ErrorResponseType';
import { usePostLoginMutation, usePostSignupMutation } from '../../../services/AuthApis/authApi';
import { demoPagesMenu, onBoardingMenu, reviewMenu } from '../../../menu';
import { RootState } from '../../../redux/store';
import Logo from '../../../assets/svg/LoginLogo.svg';
import { EmailValidation, PasswordValidation } from '../../../utils/utils';
import Spinner from '../../../components/bootstrap/Spinner';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}

const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}

	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome back! login to your account</div>
			<div className='text-center h4 text-muted mb-5'>login to your account</div>
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
};

const Login: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token, onBoardStep } = useSelector((state: RootState) => state.admin);
	const [postLogin] = usePostLoginMutation();
	const [postSignup] = usePostSignupMutation();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [checked, setChecked] = useState(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const fcmToken = localStorage.getItem('fcmToken');

	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};
	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
	};

	const loginHandler = async () => {
		setIsLoading(true);
		try {
			if (email.length === 0 || password.length === 0) {
				message.error('please fill data in fields');
				setIsLoading(false);
				return;
			}

			if (EmailValidation(email) === false) {
				message.error(`Please Enter Valid Email`);
				setIsLoading(false);
				return;
			}

			await postLogin({ email, password, fcmToken })
				.unwrap()
				.then(async (res) => {
					const { result, message: msg, data } = res as LoginResponse;
					if (result) {
						dispatch(setUserData(data));
						dispatch(setReviewStatus(data?.status));
						dispatch(setIsBoarding(data?.isOnboard));
						setIsLoading(false);
						if (!data?.isOnboard) {
							navigate(`../${onBoardingMenu.onBoarding.path}`);
						} else if (
							data?.isOnboard &&
							(data?.status === null || data?.status === 'pending')
						) {
							navigate(`../${reviewMenu.blank.path}`);
						} else {
							navigate('/');
						}
						message.success(msg);
					} else {
						message.error(msg);
						setIsLoading(false);
					}
				})
				.catch((error) => {
					message.error(error.data.message);
					setIsLoading(false);
				});
		} catch (error) {
			const err = error as ErrorResponseType;
			message.error(err.data?.message);
			setIsLoading(false);
		}
	};

	const signUpHandler = async () => {
		setIsLoading(true);
		try {
			if (email.length === 0 || password.length === 0 || confirmPassword.length === 0) {
				message.error('please fill data in fields');
				setIsLoading(false);
				return;
			}

			if (EmailValidation(email) === false) {
				message.error(`Please Enter Valid Email`);
				setIsLoading(false);
				return;
			}

			if (PasswordValidation(password) === false) {
				message.error(`Please Enter Valid Password`);
				setIsLoading(false);
				return;
			}

			if (password.length < 6 || confirmPassword.length < 6) {
				message.error('password or Confirm-password must be 6 characters long');
				setIsLoading(false);
				return;
			}

			if (password !== confirmPassword) {
				message.error('password and Confirm-password must be same');
				setIsLoading(false);
				return;
			}
			await postSignup({ email, password, confirmPassword, fcmToken })
				.unwrap()
				.then(async (res) => {
					const { result, message: msg, data } = res as SignupResponse;
					if (result) {
						dispatch(setUserData(data));
						dispatch(setIsBoarding(data?.isOnboard));
						setIsLoading(false);
						if (!data?.isOnboard) {
							navigate(`../${onBoardingMenu.onBoarding.path}`);
						} else {
							navigate('/');
						}
						message.success(msg);
					} else {
						message.error(msg);
						setIsLoading(false);
					}
				})
				.catch((error) => {
					message.error(error.data.message);
					setIsLoading(false);
				});
		} catch (error) {
			const err = error as ErrorResponseType;
			message.error(err.data?.message);
			setIsLoading(false);
		}
	};

	const onChange = (e: CheckboxChangeEvent) => {
		setChecked(e.target.checked);
	};

	const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			if (singUpStatus) {
				signUpHandler();
			} else {
				loginHandler();
			}
		}
	};

	useEffect(() => {
		if (token && (onBoardStep === 2 || onBoardStep === 3)) {
			navigate('/onBoarding');
		}
	}, [token, onBoardStep, navigate]);

	return (
		<div className='row col-12 p-0 m-0'>
			<div className='row col-6' style={{ backgroundColor: '#F6F6F6' }}>
				<div className='row col-12 justify-content-center align-content-center py-5'>
					<div className='col-6'>
						<p className='fs-3 fst-italic'>Massage Key</p>
						<div>
							<img src={Logo} alt='logo' height='300' width='300' />
						</div>
					</div>
				</div>
			</div>
			<div className='row col-6' style={{ margin: '0 auto' }}>
				<form className='row'>
					{singUpStatus ? (
						<div className='row col-12 justify-content-center align-content-center py-5'>
							<div className='col-7 py-1 mt-1'>
								<div className='text-left h1 fw-bold mt-5'>Create Account,</div>
								<div className='text-left h4 text-muted mb-5'>
									Sign up to get started!
								</div>
								<Label htmlFor='Email' className='m-lg-2 fw-bold'>
									Email
								</Label>
								<Input
									autoComplete='email'
									placeholder='input your email in here'
									value={email}
									onChange={(e: any) => setEmail(e.target.value.trim())}
									className='mb-4 py-3'
								/>
								<Label htmlFor='Password' className='m-lg-2 fw-bold'>
									Password
								</Label>
								<div className='input-group mb-4'>
									<Input
										className='py-3'
										type={showPassword ? 'text' : 'password'}
										autoComplete='current-password'
										placeholder='input your password in here'
										value={password}
										onChange={(e: any) => setPassword(e.target.value.trim())}
										onKeyPress={handleKeyPress}
									/>
									<span
										className='input-group-text'
										role='button'
										tabIndex={0}
										onClick={togglePasswordVisibility}
										onKeyDown={(e) => {
											if (e.key === 'Enter' || e.key === ' ')
												togglePasswordVisibility();
										}}
										aria-label={
											showPassword ? 'Hide password' : 'Show password'
										}>
										{showPassword ? (
											<IoEyeOffOutline size={20} />
										) : (
											<IoEyeOutline size={20} />
										)}
									</span>
								</div>
								<Label htmlFor='Password' className='m-lg-2 fw-bold'>
									Confirm Password
								</Label>
								<div className='input-group mb-4'>
									<Input
										className='py-3'
										type={showConfirmPassword ? 'text' : 'password'}
										autoComplete='current-password'
										placeholder='input your password in here'
										value={confirmPassword}
										onChange={(e: any) =>
											setConfirmPassword(e.target.value.trim())
										}
										onKeyPress={handleKeyPress}
									/>
									<span
										className='input-group-text'
										role='button'
										tabIndex={0}
										onClick={toggleConfirmPasswordVisibility}
										onKeyDown={(e) => {
											if (e.key === 'Enter' || e.key === ' ')
												toggleConfirmPasswordVisibility();
										}}
										aria-label={
											showConfirmPassword ? 'Hide password' : 'Show password'
										}>
										{showConfirmPassword ? (
											<IoEyeOffOutline size={20} />
										) : (
											<IoEyeOutline size={20} />
										)}
									</span>
								</div>
								<Checkbox value='A' className='mt-3 fw-bold' onChange={onChange}>
									<p style={{ color: '#9A9AB0' }} className='mt-3'>
										I have read and agree to the Terms of Service
									</p>
								</Checkbox>
								<Button
									isDisable={
										checked === false ||
										email.length === 0 ||
										password.length === 0 ||
										confirmPassword.length === 0
									}
									color='info'
									className='w-100 py-3 mt-5 rounded-pill'
									onClick={signUpHandler}>
									{isLoading && <Spinner isSmall inButton isGrow />}
									Sign Up
								</Button>
								<div className='col-12 mt-5'>
									<p className='' style={{ textAlign: 'center' }}>
										Already have an account?{' '}
										<a
											href='#0'
											className='fw-bold cursor-pointer text-decoration-none'
											onClick={() => {
												setSingUpStatus(false);
												setEmail('');
												setPassword('');
											}}>
											Sign In Now
										</a>
									</p>
								</div>
							</div>
						</div>
					) : (
						<div className='row col-12 justify-content-center align-content-center py-5'>
							<div className='col-7 py-1 mt-4'>
								<div className='text-left h1 fw-bold mt-5'>Welcome back!</div>
								<div className='text-left h4 text-muted mb-5'>
									login to your account
								</div>
								<Label htmlFor='Email' className='m-lg-2 fw-bold'>
									Email
								</Label>
								<Input
									autoComplete='username'
									placeholder='input your email in here'
									value={email}
									onChange={(e: any) => setEmail(e.target.value.trim())}
									className='mb-4 py-3'
								/>
								<Label htmlFor='Password' className='m-lg-2 fw-bold'>
									Password
								</Label>
								<div className='input-group mb-1'>
									<Input
										className='py-3'
										type={showPassword ? 'text' : 'password'}
										autoComplete='current-password'
										placeholder='input your password in here'
										value={password}
										onChange={(e: any) => setPassword(e.target.value.trim())}
										onKeyPress={handleKeyPress}
									/>
									<span
										className='input-group-text'
										role='button'
										tabIndex={0}
										onClick={togglePasswordVisibility}
										onKeyDown={(e) => {
											if (e.key === 'Enter' || e.key === ' ')
												togglePasswordVisibility();
										}}
										aria-label={
											showPassword ? 'Hide password' : 'Show password'
										}>
										{!showPassword ? (
											<IoEyeOffOutline size={20} />
										) : (
											<IoEyeOutline size={20} />
										)}
									</span>
								</div>
								<div className='col-12 mt-0'>
									<p className='' style={{ textAlign: 'right' }}>
										<a
											href='#0'
											className='fw-bold cursor-pointer text-decoration-none'
											onClick={() => {
												setSingUpStatus(true);
												setEmail('');
												setPassword('');
												navigate(`../${demoPagesMenu.forgotPassword.path}`);
											}}>
											Forgot Password?
										</a>
									</p>
								</div>
								<Button
									isDisable={email.length === 0 || password.length === 0}
									color='warning'
									className='w-100 py-3 mt-5 rounded-pill'
									onClick={loginHandler}>
									{isLoading && <Spinner isSmall inButton isGrow />}
									Sign In
								</Button>
								<div className='col-12 mt-5'>
									<p className='' style={{ textAlign: 'center' }}>
										Doesn’t have an account?{' '}
										<a
											href='#0'
											className='fw-bold cursor-pointer text-decoration-none'
											onClick={() => {
												setSingUpStatus(true);
												setEmail('');
												setPassword('');
											}}>
											Sign Up Now
										</a>
									</p>
								</div>
							</div>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
export default Login;
