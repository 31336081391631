// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/no-unused-prop-types */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/require-default-props */
// eslint-disable-next-line prettier/prettier, eslint-comments/disable-enable-pair
/* eslint-disable react/no-array-index-key */

import React, { useState, useEffect, FC, useCallback } from 'react';
import { Upload, message, Radio, Button as AntdButton, Pagination } from 'antd';
import axios from 'axios';
import { RcFile, UploadChangeParam } from 'antd/es/upload/interface';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import {
	useAddMasseuseMutation,
	useDeleteServiceProviderMutation,
	useGetMasseuseListMutation,
	useUpdateMasseuseDetailsMutation,
} from '../../../../services/DashboardApis/dashboardApi';
import {
	MasseusesOnBoardData,
	MasseuseDetailResponse,
	ApiResponse,
	MasseuseResponse,
	MasseuseData,
} from '../../../../services/DashboardApis/type';
import { useGetPresignedUrlMutation } from '../../../../services/OnBoardingApis/OnBoardingApi';
import { PresignedResponse } from '../../../../services/DashboardApis/type';
import {
	setOnBoardingStep,
	setOnBoardMasseuseServiceProvidersCurrentPage,
} from '../../../../redux/slice';
import { RootState } from '../../../../redux/store';
import Card, { CardBody, CardTitle } from '../../../../components/bootstrap/Card';
import Avatar from '../../../../components/Avatar';
import NoImage from '../../../../assets/no-user-image.png';
import Loader from '../../../../components/Loader';

interface Props {
	isFrom: string;
	setModal?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
	getMasseuseHandler: (searchQuery: string | null) => Promise<void>;
}

const uploadButton = (
	<button style={{ border: 0, background: 'none' }} type='button'>
		<PlusOutlined />
		<div style={{ marginTop: 8 }}>Upload</div>
	</button>
);

const OnBoardMasseuseManagement: FC<Props> = ({ isFrom, setModal, getMasseuseHandler }) => {
	const dispatch = useDispatch();
	const { token, userData, id } = useSelector((state: RootState) => state.admin);
	const onBoardMasseuseServiceProvidersCurrentPage =
		useSelector((state: RootState) => state.admin.onBoardMasseuseServiceProvidersCurrentPage) ||
		1;
	const [masseuseId, setMasseuseId] = useState<string | undefined>();
	const [fileData, setFileData] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaveComplete, setIsSaveComplete] = useState(false);
	const [selectedProfileInfo, setSelectedProfileInfo] = useState<UploadChangeParam | null>(null);
	const [filesData, setFilesData] = useState<any[]>([]);
	const [schedules, setSchedules] = useState([
		{
			day: 'Monday',
			times: [{ startTime: '', endTime: '' }],
		},
	]);
	const [masseuseData, setMasseuseData] = useState<MasseusesOnBoardData[]>([
		{
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			gender: '',
			number: '',
			description: '',
			profileImage: '',
			experience: '',
			slots: schedules,
		},
	]);
	const [masseuseResponse, setMasseuseResponse] = useState<MasseuseResponse | null>(null);
	const [masseuses, setMasseuses] = useState<MasseuseData[]>();
	const [getMasseuseList] = useGetMasseuseListMutation();
	const [getPresignedUrl] = useGetPresignedUrlMutation();
	const [addMasseuse] = useAddMasseuseMutation();
	const [updateMasseuseDetails] = useUpdateMasseuseDetailsMutation();
	const [deleteServiceProvider] = useDeleteServiceProviderMutation();

	const daysOfWeek = [
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
		'Sunday',
	];

	// *************************************************************
	// NOTE: Helper Methods
	// *************************************************************

	const addMasseuseRow = () => {
		const newMasseuse = {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			gender: '',
			description: '',
			profileImage: '',
			number: '',
			experience: '',
			slots: schedules,
		};
		setMasseuseData([...masseuseData, newMasseuse]);
		setIsSaveComplete(false);
	};

	const uploadProfileImage = async (
		info: UploadChangeParam,
	): Promise<{
		updatedMasseuseData: MasseusesOnBoardData[] | null;
		uploadedFilename: string | null;
	}> => {
		setIsLoading(true);
		const selectedDoc = info.file;
		setFilesData(info.fileList);

		try {
			const response: any = await getPresignedUrl({
				filename: info.file.name,
				keyType: 'masseuseProfile',
			}).unwrap();

			const presignedData = response?.data;
			const filename = presignedData?.filename;

			const updatedData = masseuseData.map((masseuse) => ({
				...masseuse,
				profileImage: filename,
			}));
			setMasseuseData(updatedData);

			await axios.put(`${presignedData.presignedUrl}`, selectedDoc);
			message.success('Profile image uploaded successfully');
			setIsLoading(false);
			return { updatedMasseuseData: updatedData, uploadedFilename: filename };
		} catch (err: any) {
			message.error(err?.error || 'Something went wrong with profile image upload...');
			setIsLoading(false);
			throw err;
		}
	};

	const handleAddTimeSlot = (index: number) => {
		const newSchedules = [...schedules];
		newSchedules[index].times.push({ startTime: '', endTime: '' });
		setSchedules(newSchedules);
	};

	const handleDeleteTimeSlot = (scheduleIndex: number, timeIndex: number) => {
		setSchedules((prevSchedules) =>
			prevSchedules.map((schedule, sIndex) => {
				if (sIndex === scheduleIndex) {
					const updatedTimes = schedule.times.filter((_, tIndex) => tIndex !== timeIndex);
					return {
						...schedule,
						times: updatedTimes,
					};
				}
				return schedule;
			}),
		);
	};

	const handleAddSchedule = () => {
		setSchedules([...schedules, { day: '', times: [{ startTime: '', endTime: '' }] }]);
	};

	const handleDeleteSchedule = (index: number) => {
		setSchedules((prevData) => prevData.filter((_, i) => i !== index));
	};

	const handleScheduleChange = (
		index: number,
		field: 'day' | 'times',
		value: string | { startTime: string; endTime: string }[],
	) => {
		const newSchedules = [...schedules];

		if (field === 'day' && typeof value === 'string') {
			newSchedules[index][field] = value;
		} else if (field === 'times' && Array.isArray(value)) {
			newSchedules[index][field] = value;
		}

		setSchedules(newSchedules);
	};

	const handleTimeChange = (
		scheduleIndex: number,
		timeIndex: number,
		field: 'startTime' | 'endTime',
		value: string,
	) => {
		const newSchedules = [...schedules];
		const currentTimeslot = newSchedules[scheduleIndex].times[timeIndex];

		currentTimeslot[field] = value;

		const startTime = new Date(`1970-01-01T${currentTimeslot.startTime}`);
		const endTime = new Date(`1970-01-01T${currentTimeslot.endTime}`);

		if (currentTimeslot.startTime === currentTimeslot.endTime) {
			message.warning('Start Time and End Time cannot be the same.');
			return;
		}

		if (endTime <= startTime) {
			message.warning('End Time must be later than Start Time.');
			return;
		}

		setSchedules(newSchedules);
	};

	// eslint-disable-next-line consistent-return
	const addMasseuseHandler = async (index: number, updatedData?: MasseusesOnBoardData[]) => {
		const currentMasseuse = updatedData ? updatedData[index] : masseuseData[index];
		if (!currentMasseuse) {
			message.error('All data required');
			return;
		}

		setIsLoading(true);

		try {
			const response = await addMasseuse({
				centerId: masseuseId,
				payload: currentMasseuse,
			}).unwrap();

			const { result, message: msg } = response as MasseuseDetailResponse;

			if (result) {
				message.success(msg);
				setIsSaveComplete(true);
				setIsLoading(false);
				resetFormData();
				if (setModal) {
					setModal(false);
					if (isFrom === 'dashboard') {
						getMasseuseHandler(null);
					}
				}
			}
		} catch (err: any) {
			message.error(err?.data?.message);
			setIsLoading(false);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSaveButtonClick = async (index: number) => {
		setIsLoading(true);
		const currentMasseuse = masseuseData[index];

		const requiredFields = [
			{ field: currentMasseuse.email, message: 'Email is required', key: 'email' },
			{ field: currentMasseuse.number, message: 'Phone Number is required', key: 'number' },
			{
				field: currentMasseuse.firstName,
				message: 'First Name is required',
				key: 'firstName',
			},
			{ field: currentMasseuse.lastName, message: 'Last Name is required', key: 'lastName' },
			{ field: currentMasseuse.password, message: 'Password is required', key: 'password' },
			{ field: currentMasseuse.gender, message: 'Gender is required', key: 'gender' },
			{
				field: currentMasseuse.description,
				message: 'Description is required',
				key: 'description',
			},
			{
				field: currentMasseuse.experience,
				message: 'Experience is required',
				key: 'experience',
			},
		];

		for (const { field, message: msg, key } of requiredFields) {
			if (!field) {
				message.error(msg);

				const element = document.getElementById(key);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth', block: 'center' });
					element.focus();
				}

				return;
			}
		}

		if (!selectedProfileInfo) {
			message.error('Please upload the profile image before saving.');
			setIsLoading(false);
			return;
		}

		try {
			const { updatedMasseuseData, uploadedFilename } =
				await uploadProfileImage(selectedProfileInfo);

			if (uploadedFilename) {
				await addMasseuseHandler(index, updatedMasseuseData!);
				getPreviousMasseuseHandler();
				setIsLoading(false);
			} else {
				message.error('Profile image upload failed, please try again.');
				setIsLoading(false);
			}
		} catch (err) {
			message.error('Some uploads failed, please try again.');
			setIsLoading(false);
		}
	};

	const resetFormData = () => {
		setMasseuseData([
			{
				firstName: '',
				lastName: '',
				email: '',
				password: '',
				gender: '',
				experience: '',
				description: '',
				number: '',
				profileImage: '',
				slots: [
					{
						day: 'Monday',
						times: [{ startTime: '', endTime: '' }],
					},
				],
			},
		]);
		setFileData([]);
		setSchedules([
			{
				day: 'Monday',
				times: [{ startTime: '', endTime: '' }],
			},
		]);
	};

	const handleDeleteMasseuse = (index: number) => {
		setMasseuseData((prevData) => prevData.filter((_, i) => i !== index));
	};

	const getPreviousMasseuseHandler = useCallback(async () => {
		setIsLoading(true);
		await getMasseuseList({
			centerId: id,
			currentPage: onBoardMasseuseServiceProvidersCurrentPage,
		})
			.unwrap()
			.then(async (res) => {
				const {
					result,
					message: msg,
					data: masseuse,
					pagination,
				} = res as MasseuseResponse;
				if (masseuse) {
					setTimeout(() => {
						if (isFrom === 'onBoarding') {
							// setMasseuseData(masseuse as unknown as MasseusesOnBoardData[]);
							setIsSaveComplete(true);
						}
						setMasseuseResponse({ result, data: masseuse, message: msg, pagination });
						setMasseuses(masseuse);
						setIsLoading(false);
					}, 500);
				}
			})
			.catch(() => {
				message.error('Error in data fetching');
			});
	}, [getMasseuseList, id, isFrom, onBoardMasseuseServiceProvidersCurrentPage]);

	const deleteProviderHandler = async (did: string) => {
		setIsLoading(true);
		await deleteServiceProvider({ id: did, payload: { isArchived: true } })
			.unwrap()
			.then((response) => {
				const { result, message: msg } = response as MasseuseDetailResponse;
				if (result) {
					message.success(msg);
					getPreviousMasseuseHandler();
					setIsLoading(false);
				}
			})
			.catch((error) => {
				message.error(error?.data?.message);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getPreviousMasseuseHandler();
	}, [getPreviousMasseuseHandler]);

	useEffect(() => {
		setMasseuseData((prevData) =>
			prevData.map((masseuse) => ({
				...masseuse,
				slots: schedules,
			})),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [schedules]);

	useEffect(() => {
		if (userData) {
			setMasseuseId(userData.id);
		}
	}, [userData]);

	return (
		<div className='row col-12 d-flex justify-content-center'>
			{isLoading ? (
				<div className='col-12 d-flex justify-content-center'>
					<Loader />
				</div>
			) : (
				<div className='d-flex flex-column col-10 align-items-center justify-content-center py-5'>
					<p className='fs-3 mb-0 fw-semibold col-12 text-start'>Add Masseuse</p>
					<div className='row col-12 d-flex row-gap-4 justify-content-center'>
						{masseuses && isFrom === 'onBoarding' && (
							<div className='col-12'>
								{masseuses &&
									masseuses?.map((masseuse, index) => (
										<div
											style={{
												height: '50vh',
												overflowY: 'auto',
												padding: '10px',
											}}>
											<Card>
												<CardBody>
													<Card>
														<CardBody>
															<CardTitle>
																{masseuse?.firstName}{' '}
																{masseuse?.lastName}
															</CardTitle>
															{masseuse.profileImage ? (
																<Avatar
																	src={`${process.env.REACT_APP_MEDIA_URL}${masseuse?.profileImage}`}
																	size={100}
																	aria-label='Avatar'
																	className=''
																/>
															) : (
																<Avatar
																	src={NoImage}
																	size={500}
																	aria-label='Avatar'
																/>
															)}
															<div className='col-12 d-flex flex-column gap-4 mt-4'>
																<div className='d-flex flex-wrap align-items-center'>
																	<span
																		style={{
																			fontSize: 14,
																			width: '20%',
																		}}>
																		Email :
																	</span>
																	<span
																		style={{
																			fontSize: 14,
																			fontWeight: 600,
																			width: '80%',
																		}}>
																		{masseuse?.email}
																	</span>
																</div>
																<div className='d-flex justify-content-between align-items-center'>
																	<span
																		style={{
																			fontSize: 14,
																			width: '20%',
																		}}>
																		Gender :
																	</span>
																	<span
																		style={{
																			fontSize: 14,
																			fontWeight: 600,
																			width: '80%',
																		}}>
																		{masseuse?.gender}
																	</span>
																</div>
																<div className='d-flex justify-content-between align-items-center'>
																	<span
																		style={{
																			fontSize: 14,
																			width: '20%',
																		}}>
																		Description :
																	</span>
																	<br />
																	<span
																		style={{
																			fontSize: 14,
																			fontWeight: 600,
																			width: '80%',
																		}}>
																		{masseuse?.description}
																	</span>
																</div>
																<div className='d-flex justify-content-between align-items-center'>
																	<span
																		style={{
																			fontSize: 14,
																			width: '20%',
																		}}>
																		Experience :
																	</span>
																	<br />
																	<span
																		style={{
																			fontSize: 14,
																			fontWeight: 600,
																			width: '80%',
																		}}>
																		{masseuse?.experience}
																	</span>
																</div>
															</div>
														</CardBody>
													</Card>
													{masseuse?.slots?.map((slot) => (
														<Card key={index as number}>
															<CardBody
																isScrollable
																className='table-responsive h-100'>
																<div className='fs-6 fw-bold'>
																	{slot.day}'s slots
																</div>
																<table className='table table-modern table-hover'>
																	<thead>
																		<tr>
																			<th>Index</th>
																			<th>Start Time</th>
																			<th>End Time</th>
																			<th>Booking Status</th>
																		</tr>
																	</thead>
																	<tbody>
																		{slot.times.map(
																			(
																				slotTimes,
																				slotIndex,
																			) => (
																				<tr
																					key={
																						slotIndex as number
																					}>
																					<td>
																						{slotIndex +
																							1}
																					</td>
																					<td>
																						<div className='fs-6 fw-bold'>
																							{
																								slotTimes.startTime
																							}
																						</div>
																					</td>
																					<td>
																						<div className='fs-6 fw-bold'>
																							{
																								slotTimes.endTime
																							}
																						</div>
																					</td>
																					<td>
																						<div className='fs-6 fw-bold'>
																							{slotTimes.isBooked
																								? 'Booked'
																								: 'Not booked'}
																						</div>
																					</td>
																				</tr>
																			),
																		)}
																	</tbody>
																</table>
																<AntdButton
																	className='d-flex align-items-center col-12 py-4 btn btn-danger border-1 mt-2'
																	onClick={() => {
																		deleteProviderHandler(
																			masseuse?.id,
																		);
																	}}>
																	Delete masseuse
																</AntdButton>
															</CardBody>
														</Card>
													))}
												</CardBody>
											</Card>
											<div
												className='paginateSection'
												style={{
													padding: '10px 0px',
													display: 'flex',
													justifyContent: 'center',
												}}>
												{masseuseResponse?.pagination && (
													<Pagination
														className='pagination'
														current={
															onBoardMasseuseServiceProvidersCurrentPage
														}
														pageSize={
															masseuseResponse?.pagination.perPage
														}
														total={
															masseuseResponse?.pagination.totalDocs
														}
														onChange={(currentPage: number) => {
															dispatch(
																setOnBoardMasseuseServiceProvidersCurrentPage(
																	currentPage,
																),
															);
														}}
														showSizeChanger={false}
													/>
												)}
											</div>
										</div>
									))}
							</div>
						)}
						{masseuseData?.map((owner, index) => (
							<div className='d-flex flex-column  align-items-center col-12 px-3 py-4 border border-2 rounded-2'>
								<React.Fragment key={index}>
									<div className='row g-4 col-12'>
										<div className='col-6'>
											<p className='m-lg-2 fw-bold'>Masseuse Picture</p>
											<Upload
												accept='.jpg, .jpeg, .png'
												listType='picture-card'
												beforeUpload={() => false}
												maxCount={1}
												rootClassName='w-10'
												onChange={(info) => setSelectedProfileInfo(info)}
												onPreview={() => null}>
												{fileData.length >= 1 ? null : uploadButton}
											</Upload>
										</div>
									</div>
									<div className='row g-4 mt-4 col-12'>
										<div className='col-6'>
											<p className='m-lg-1 fw-bold'>Email</p>
											<div className='col-12'>
												<FormGroup id='email' isFloating>
													<Input
														value={owner.email}
														onChange={(
															e: React.ChangeEvent<HTMLInputElement>,
														) => {
															const newMasseuseData = [
																...masseuseData,
															];
															newMasseuseData[index].email =
																e.target.value;
															setMasseuseData(newMasseuseData);
														}}
														placeholder='Email'
														type='email'
														name='email'
														id='email'
													/>
												</FormGroup>
											</div>
										</div>
										<div className='col-6'>
											<p className='m-lg-1 fw-bold'>Phone Number</p>
											<div className='col-12'>
												<FormGroup id='number' isFloating>
													<PhoneInput
														inputStyle={{
															width: '100%',
															paddingTop: '20px',
															paddingBottom: '20px',
															marginLeft: '10px',
															borderRadius: '10px',
															background: '#f8f9fa',
														}}
														buttonStyle={{
															borderTopLeftRadius: '10px',
															borderBottomLeftRadius: '10px',
															padding: '5px',
														}}
														country='us'
														value={owner.number}
														onChange={(value: string) => {
															const newMasseuseData = [
																...masseuseData,
															];
															const phoneNumberPattern = /^[0-9]*$/;

															if (
																!phoneNumberPattern.test(
																	value.replace('+1', ''),
																)
															) {
																return;
															}

															newMasseuseData[index].number = value;
															setMasseuseData(newMasseuseData);
														}}
														inputProps={{
															maxLength: 17,
															focus: false,
														}}
													/>
												</FormGroup>
											</div>
										</div>
									</div>
									<div className='row g-4 mt-4 col-12'>
										<div className='col-6'>
											<p className='m-lg-1 fw-bold'>First Name</p>
											<div className='col-12'>
												<FormGroup id='firstName' isFloating>
													<Input
														value={owner.firstName}
														onChange={(
															e: React.ChangeEvent<HTMLInputElement>,
														) => {
															const newMasseuseData = [
																...masseuseData,
															];
															newMasseuseData[index].firstName =
																e.target.value;
															setMasseuseData(newMasseuseData);
														}}
													/>
												</FormGroup>
											</div>
										</div>
										<div className='col-6'>
											<p className='m-lg-1 fw-bold'>Last Name</p>
											<div className='col-12'>
												<FormGroup id='lastName' isFloating>
													<Input
														value={owner.lastName}
														onChange={(
															e: React.ChangeEvent<HTMLInputElement>,
														) => {
															const newMasseuseData = [
																...masseuseData,
															];
															newMasseuseData[index].lastName =
																e.target.value;
															setMasseuseData(newMasseuseData);
														}}
													/>
												</FormGroup>
											</div>
										</div>
									</div>
									<div className='row g-4 mt-4 col-12'>
										<div className='col-6'>
											<p className='m-lg-1 fw-bold'>Password</p>
											<div className='col-12'>
												<FormGroup id='password' isFloating>
													<Input
														type='password'
														value={owner.password}
														onChange={(
															e: React.ChangeEvent<HTMLInputElement>,
														) => {
															const newMasseuseData = [
																...masseuseData,
															];
															newMasseuseData[index].password =
																e.target.value;
															setMasseuseData(newMasseuseData);
														}}
													/>
												</FormGroup>
											</div>
										</div>
										<div className='col-6'>
											<p className='m-lg-1 fw-bold'>Gender</p>
											<div className='col-12 d-flex jus'>
												<Radio.Group
													id='gender'
													onChange={(e) => {
														const newMasseuseData = [...masseuseData];
														newMasseuseData[index].gender =
															e.target.value;
														setMasseuseData(newMasseuseData);
													}}
													value={owner.gender}>
													<Radio value='Male'>Male</Radio>
													<Radio value='Female'>Female</Radio>
													<Radio value='Other'>Other</Radio>
												</Radio.Group>
											</div>
										</div>
									</div>
									<div className='row g-4 mt-4 col-12'>
										<p className='m-lg-1 fw-bold'>Description</p>
										<FormGroup id='description'>
											<Textarea
												value={owner.description}
												onChange={(
													e: React.ChangeEvent<HTMLInputElement>,
												) => {
													const newMasseuseData = [...masseuseData];
													newMasseuseData[index].description =
														e.target.value;
													setMasseuseData(newMasseuseData);
												}}
											/>
										</FormGroup>
									</div>
									<div className='row g-4 mt-4 col-12'>
										<p className='fw-bold'>Experience</p>
										<FormGroup id='experience'>
											<Textarea
												value={owner.experience}
												onChange={(
													e: React.ChangeEvent<HTMLInputElement>,
												) => {
													const newMasseuseData = [...masseuseData];
													newMasseuseData[index].experience =
														e.target.value;
													setMasseuseData(newMasseuseData);
												}}
											/>
										</FormGroup>
									</div>
									<div className='row g-4 mt-4 col-12'>
										<p className='fw-bold'>Slots</p>
										{schedules.map((schedule, scheduleIndex) => (
											<div
												key={scheduleIndex}
												className='d-flex flex-column align-items-center mb-1 p-3 border border-2 rounded-2'>
												<div className='row col-12 mb-2'>
													<div className='row'>
														<label htmlFor='day'>Day</label>
														<select
															className='form-select'
															value={schedule.day}
															onChange={(e) =>
																handleScheduleChange(
																	scheduleIndex,
																	'day',
																	e.target.value,
																)
															}>
															<option value=''>Select Day</option>
															{daysOfWeek.map((day) => (
																<option key={day} value={day}>
																	{day}
																</option>
															))}
														</select>
													</div>
												</div>
												<p className='row col-12 fw-bold'>Times</p>
												<div className='d-flex flex-column align-items-center row-gap-3 row g-4 mt-4 p-3 col-12 border border-2 rounded'>
													{schedule.times.map((timeSlot, timeIndex) => (
														<div
															key={timeIndex}
															className='row d-flex border border-1 rounded-2 p-2'>
															<div className=''>
																<label htmlFor='select'>
																	Start Time
																</label>
																<input
																	type='time'
																	className='form-control'
																	value={timeSlot.startTime}
																	onChange={(e) =>
																		handleTimeChange(
																			scheduleIndex,
																			timeIndex,
																			'startTime',
																			e.target.value,
																		)
																	}
																/>
															</div>
															<div className=''>
																<label htmlFor='select'>
																	End Time
																</label>
																<input
																	type='time'
																	className='form-control'
																	value={timeSlot.endTime}
																	onChange={(e) =>
																		handleTimeChange(
																			scheduleIndex,
																			timeIndex,
																			'endTime',
																			e.target.value,
																		)
																	}
																/>
															</div>
															<div className='d-flex align-items-center mt-2'>
																<button
																	type='button'
																	className='btn btn-primary'
																	onClick={() =>
																		handleAddTimeSlot(
																			scheduleIndex,
																		)
																	}>
																	+ Add Slot
																</button>
																{schedule.times.length > 1 && (
																	<button
																		type='button'
																		className='btn btn-danger ms-2'
																		onClick={() =>
																			handleDeleteTimeSlot(
																				scheduleIndex,
																				timeIndex,
																			)
																		}>
																		Delete Slot
																	</button>
																)}
															</div>
														</div>
													))}
												</div>
												{schedules.length > 1 && (
													<button
														type='button'
														className='btn btn-danger mt-3'
														onClick={() =>
															handleDeleteSchedule(scheduleIndex)
														}>
														Delete Day
													</button>
												)}
											</div>
										))}
										<button
											type='button'
											className='col-3 btn btn-success'
											onClick={handleAddSchedule}>
											+ Add More Day
										</button>
									</div>
									<div className='col-12 g-4 mt-4 col-8'>
										<AntdButton
											className='d-flex align-items-center col-12 py-4 btn btn-info border-1'
											onClick={() => handleSaveButtonClick(index)}>
											Save
										</AntdButton>
										<AntdButton
											disabled={masseuseData?.length === 1}
											className='d-flex align-items-center col-12 py-4 btn btn-danger border-1 mt-2'
											onClick={() => {
												handleDeleteMasseuse(index);
											}}>
											Delete
										</AntdButton>
									</div>
								</React.Fragment>
							</div>
						))}
					</div>
					{isFrom === 'onBoarding' && (
						<div className='row col-10 g-4 mt-2'>
							<AntdButton
								disabled={!masseuses?.length}
								className='d-flex align-items-center col-12 py-4 btn btn-primary border-1'
								onClick={addMasseuseRow}>
								+ Add
							</AntdButton>
						</div>
					)}
					{isFrom === 'onBoarding' && (
						<div className='mt-5 row col-11 d-flex justify-content-between'>
							<button
								type='button'
								className='col-3 btn btn-danger border-1 px-5 py-3'
								onClick={() => {
									dispatch(setOnBoardingStep(1));
								}}>
								Previous
							</button>
							<button
								type='button'
								className='col-3 btn btn-primary border-1 px-5 py-3'
								onClick={() => {
									dispatch(setOnBoardingStep(3));
								}}>
								Next
							</button>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default OnBoardMasseuseManagement;
