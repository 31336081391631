// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-else-return */
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

console.log('API Key:', process.env.REACT_APP_API_KEY);
console.log('VAPID Key:', process.env.REACT_APP_VAPID_KEY);

// Firebase configuration
const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

interface NavigatorBrave extends Navigator {
	brave?: {
		isBrave: () => Promise<boolean>;
	};
}

// Helper function to detect the browser
function getBrowser(): string {
	const navigatorTyped = navigator as NavigatorBrave; // Type assertion for custom 'brave' property

	if (navigatorTyped.brave) {
		return 'Brave';
	}

	// eslint-disable-next-line prefer-destructuring
	const userAgent = navigator.userAgent;

	if (userAgent.includes('Chrome')) {
		return 'Chrome';
	}
	if (userAgent.includes('Firefox')) {
		return 'Firefox';
	}
	if (userAgent.includes('Edg')) {
		return 'Edge';
	}
	if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
		return 'Safari';
	}

	return 'Unknown';
}

// Log the current browser
console.log('Current Browser:', getBrowser());

export const requestPermission = () => {
	const browser = getBrowser(); // Get the current browser
	if (browser === 'Safari') {
		console.warn('Safari does not support Firebase push notifications.');
		return;
	}

	// Request user permission for notifications
	// eslint-disable-next-line consistent-return
	Notification.requestPermission().then((permission) => {
		if (permission === 'granted') {
			console.log('Notification User Permission Granted.');
			// Get FCM token
			return getToken(messaging, {
				vapidKey: process.env.REACT_APP_VAPID_KEY,
			})
				.then((currentToken) => {
					if (currentToken) {
						localStorage.setItem('fcmToken', currentToken);
						if (process.env.REACT_APP_DEBUG_MODE === 'true') {
							console.log('Client Token: ', currentToken);
						}
					} else {
						console.log('Failed to generate the app registration token.');
					}
				})
				.catch((err) => {
					console.log('An error occurred when requesting to receive the token.', err);
				});
		} else {
			console.log('User did not grant notification permission.');
		}
	});
};

// Call requestPermission when the app loads
requestPermission();

export const onMessageListener = (callback: any) => {
	const unsubscribe = onMessage(messaging, (payload: any) => {
		console.log(messaging, payload, '-->foreground message');
		callback(payload);
	});

	return unsubscribe;
};
